<template>
  <AntTextArea
    :value="props.value || ''"
    @update:value="(val: string) => emit('update:value', val)"
    :disabled="formState?.disabled || disabled"
    :rows="rows"
  />
</template>

<script setup lang="ts">
import { Textarea as AntTextArea } from 'ant-design-vue/es/input'
import { useFormState } from './global-state'

const formState = useFormState()

const props = defineProps<{
  value: string | null | undefined
  disabled?: boolean
  rows?: number
}>()

const emit = defineEmits<{
  (e: 'update:value', value: string): void
}>()
</script>
